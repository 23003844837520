import axios from 'axios';
import { Dayjs } from 'dayjs';
import { coordinates } from '../services/Geocode';

type Response = {
  config: Record<string, unknown>;
  data: unknown;
  headers: Record<string, string>;
  request: Record<string, unknown>;
  status: number;
  statusText: string;
};

/* eslint-disable */
const createQuote = async (args: {
  startDate: Dayjs;
  endDate: Dayjs;
  coords: coordinates;
  location: string;
  coverageAmount: number;
}): Promise<{
  suggestedPrice: number,
  payoutTiers: {numberOfHours: number, payoutAmount: number}[],
} | null> => {
  const url = 'api/protect/quote';

  const {
    startDate,
    endDate,
    coords,
    location,
    coverageAmount,
  } = args;

  if (!coords) return null;

  try {
    const res: Response = await axios.post(url, {
      startDate,
      endDate,
      lat: coords.lat,
      long: coords.long,
      location,
      coverageAmount,
    });

    if (!res || !res.data || typeof res.data !== 'object') {
      throw new Error('expected data payload of type object');
    }

    if (!res.data || !('suggestedPrice' in res.data)) {
      throw new Error('expected data payload to have field suggestedPrice');
    }
    if (!('payoutTiers' in res.data)) {
      throw new Error('expected data payload to have field payoutTiers');
    }

    const data = res.data as { suggestedPrice: unknown, payoutTiers: unknown };

    if (typeof data.suggestedPrice !== 'number') {
      throw new Error('expected suggestedPrice to be of type number');
    }

    if (!(data.payoutTiers instanceof Array)) {
      throw new Error('expected suggestedPrice to be of type number');
    }

    const suggestedPrice = data.suggestedPrice as number;

    const payoutTiers = data.payoutTiers.map((p: unknown) => {
      if (typeof p !== 'object') throw new Error('payoutTiers must be an array of objects');

      if (!p || !('number_of_hours' in p)) throw new Error('payout tier is missing field number_of_hours');
      if (!p || !('payout_amount' in p)) throw new Error('payout tier is missing field payout_amount');

      if (typeof (p as Record<string, unknown>).number_of_hours !== 'number') {
        throw new Error('number_of_hours must be of type number');
      };

      if (typeof (p as Record<string, unknown>).payout_amount !== 'number') {
        throw new Error('payout_amount must be of type number');
      };

      return {numberOfHours: (p as Record<string, number>).number_of_hours, payoutAmount: (p as Record<string, number>).payout_amount}
    });

    // TODO: Fix to remove type assertion
    return {
      suggestedPrice,
      payoutTiers,
    };
  } catch (err) {
    throw err;
  }
};

export default {
  createQuote,
};
