import React, { SyntheticEvent, useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Typography, sDate } from '@sensible/components';
import { InputStepProps } from './InputStep.types';
import Inputs from '../../components/Inputs/Inputs';
import {
  PlaceSuggestion,
  SensibleDate,
} from '../../components/Inputs/Inputs.types';
import ProtectAPI from '../../api/protect';
import GeocodeService, { coordinates } from '../../services/Geocode';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

const InputStep: React.FC<InputStepProps> = ({
  placesToken,
  goToLastStep,
  setSuggestedPrice,
  setPayoutTiers,
  setInputValues,
}) => {
  // TODO: Move to hooks library
  useEffect((): void => {
    window.scrollTo(0, 0);
  }, []);

  useEffect((): void => {
    const dateInputContainers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );

    if (dateInputContainers.length === 0) return;

    const dateInput = dateInputContainers[0].firstChild;

    dateInput?.addEventListener('focus', () => {
      window.scrollBy({ top: 300, behavior: 'smooth' });
    });
  }, []);

  const [placesInput, setPlacesInput] = useState('');
  const [activePlace, setActivePlace] = useState<PlaceSuggestion | null>(null);
  const [placesError, setPlacesError] = useState('');

  const [dateRangeInput, setDateRangeInput] = useState<
    [string | SensibleDate, string | SensibleDate] | []
  >([]);
  const [dateError, setDateError] = useState('');
  const [currencyInput, setCurrencyInput] = useState<number | null>(null);
  const [currencyError, setCurrencyError] = useState('');
  const [sendingRequest, setSendingRequest] = useState(false);
  const [error, setError] = useState('');

  const coords = null as coordinates;
  const coordsRef = useRef(coords);

  const updateCoordsRef = (val: coordinates): void => {
    coordsRef.current = val;
  };

  const validatePlaceInput = (): boolean => {
    if (placesInput === '') {
      setPlacesError('Location is required');
      return false;
    }
    if (activePlace === null) {
      setPlacesError('Location must be a valid selection');
      return false;
    }

    return true;
  };

  const validateDateRangeInput = (): boolean => {
    if (dateRangeInput.length !== 2) {
      setDateError('Start and end dates are required');
      return false;
    }
    const startDate = dateRangeInput[0];
    const endDate = dateRangeInput[1];

    if (
      !sDate.isValid(
        typeof startDate === 'string' ? sDate.parse(startDate) : startDate
      )
    ) {
      setDateError('Start date is not a valid date');
      return false;
    }
    if (
      !sDate.isValid(
        typeof endDate === 'string' ? sDate.parse(endDate) : endDate
      )
    ) {
      setDateError('End date is not a valid date');
      return false;
    }

    return true;
  };

  const validateCurrencyInput = (): boolean => {
    if (currencyInput === null) {
      setCurrencyError('Covered amount is required');
      return false;
    }
    if (currencyInput < 10) {
      setCurrencyError('Covered amount must be greater than 10');
      return false;
    }

    return true;
  };

  const onSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    if (sendingRequest) return;

    setError('');
    setPlacesError('');
    setDateError('');
    setCurrencyError('');

    if (!validatePlaceInput()) return;
    if (!validateDateRangeInput()) return;
    if (!validateCurrencyInput()) return;

    setSendingRequest(true);

    /* This is validated above, but checked here as well for typescript recognition */
    if (dateRangeInput.length !== 2 || !activePlace || !currencyInput) {
      setSendingRequest(false);
      return;
    }

    const startDate = dayjs(dateRangeInput[0]);
    const endDate = dayjs(dateRangeInput[1]);
    const location = activePlace.description;
    const coverageAmount = currencyInput;

    try {
      await GeocodeService.getCoordinates(
        activePlace.description,
        updateCoordsRef,
        placesToken
      );

      const res = await ProtectAPI.createQuote({
        startDate,
        endDate,
        location,
        coords: coordsRef.current,
        coverageAmount,
      });

      if (!res) throw new Error('');

      setSuggestedPrice(res.suggestedPrice);
      setPayoutTiers(res.payoutTiers);
      setInputValues({
        location,
        dates: {
          startDate,
          endDate,
        },
        coverageAmount,
      });

      goToLastStep();
    } catch (err) {
      setError('An error occured');
    }

    setSendingRequest(false);
  };

  return (
    <div>
      <Inputs
        placesAPIKey={placesToken}
        handlePlacesChange={setPlacesInput}
        placesValue={placesInput}
        placesError={placesError}
        handleActivePlaceChange={setActivePlace}
        handleDateChange={setDateRangeInput}
        dateRangeValue={dateRangeInput}
        dateError={dateError}
        currencyValue={currencyInput}
        handleCurrencyChange={setCurrencyInput}
        currencyError={currencyError}
        onSubmit={onSubmit}
        sendingRequest={sendingRequest}
      />
      <Typography variant="error">{error}</Typography>
    </div>
  );
};

export default InputStep;
