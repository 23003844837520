import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ResultsStepProps } from './ResultsStep.types';
import Results from '../../components/Results/Results';

dayjs.extend(customParseFormat);

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

const ResultsStep: React.FC<ResultsStepProps> = ({
  location,
  startDate,
  endDate,
  tripTotalCost,
  quoteTotalPrice,
  payoutTiers,
  goToFirstStep,
}) => {
  // TODO: Move to hooks library
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const startDateDisplay = dayjs(startDate).format('MM/DD/YYYY');
  const endDateDisplay = dayjs(endDate).format('MM/DD/YYYY');

  // TODO: Replace with wrapper
  const numDays = endDate.diff(startDate, 'day') + 1;
  const tripCostPerDay = (tripTotalCost / numDays).toFixed(2);
  const quotePricePerDay = (quoteTotalPrice / numDays).toFixed(2);

  return (
    <Results
      location={location}
      startDate={startDateDisplay}
      endDate={endDateDisplay}
      tripTotalCost={tripTotalCost.toFixed(2)}
      tripCostPerDay={tripCostPerDay}
      quotePricePerDay={quotePricePerDay}
      payoutTiers={payoutTiers}
      goToFirstStep={goToFirstStep}
    />
  );
};

export default ResultsStep;
