import React from 'react';

const HealthCheck: React.FunctionComponent = () => {
  return (
    <div>
      <h1>Health check looks good!</h1>
    </div>
  );
};

export default HealthCheck;
