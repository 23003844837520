import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Navigation } from '@sensible/components';
import RouteConfig from '../../router/RouteConfig';
import styles from './DefaultLayout.module.scss';

const DefaultLayout: React.FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <BrowserRouter>
        <div className={styles.navWrapper}>
          <Navigation
            items={[
              {
                path: 'https://www.sensibleweather.com/',
                label: 'For Consumers',
                external: true,
                openInNewTab: false,
              },
              {
                path: 'https://www.sensibleweather.com/for-partners',
                label: 'For Partners',
                external: true,
                openInNewTab: false,
              },
              {
                path: 'https://www.sensibleweather.com/our-team',
                label: 'Our Team',
                external: true,
                openInNewTab: false,
              },
            ]}
          />
        </div>

        <>
          <Switch>
            {RouteConfig.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
            <Redirect to="/" />
          </Switch>
        </>
      </BrowserRouter>
    </div>
  );
};

export default DefaultLayout;
