import axios from 'axios';

const getConfig = async (): Promise<Record<string, unknown>> => {
  const url = 'api/config/client';

  /* eslint-disable-next-line */
  try {
    const res = await axios.get(url);

    if (res.status < 200 || res.status > 300) {
      throw new Error(`${res.status} error`);
    }

    return res.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getConfig,
};
