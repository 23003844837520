import React from 'react';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';

const App: React.FC = () => {
  return (
    <>
      <DefaultLayout />
    </>
  );
};

export default App;
