import { Landing } from '../pages/Landing/Landing';
import healthCheck from '../healthCheck/healthCheck';

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/healthz',
    exact: true,
    name: 'Health Check',
    component: healthCheck,
  },
];

export default routes;
