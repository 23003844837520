import { Loader } from '@googlemaps/js-api-loader';

// TODO: move to utils service

export type coordinates = { lat: number; long: number } | null;

const getCoordinates = async (
  address: string,
  updateRef: (val: coordinates) => void,
  apiKey: string
): Promise<void> => {
  if (!address) return;

  const loader = new Loader({
    apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  const callback = (
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus
  ): void => {
    if (status !== google.maps.GeocoderStatus.OK) return;
    if (!results || results.length === 0) return;

    updateRef({
      lat: results[0].geometry.location.lat(),
      long: results[0].geometry.location.lng(),
    });
  };

  await loader.load().then(async (google) => {
    const geocoder = new google.maps.Geocoder();

    const req: google.maps.GeocoderRequest = {
      address,
    };

    await geocoder.geocode(req, callback);
  });
};

export default {
  getCoordinates,
};
