import React from 'react';
import {
  Typography,
  CurrencyInput,
  DateRangeInput,
  PlacesInput,
  Button,
} from '@sensible/components';
import { InputsProps } from './Inputs.types';
import styles from './inputs.module.scss';

const Inputs: React.FC<InputsProps> = ({
  placesAPIKey,
  handlePlacesChange,
  placesValue,
  placesError,
  handleActivePlaceChange,
  handleDateChange,
  dateRangeValue,
  dateError,
  handleCurrencyChange,
  currencyValue,
  currencyError,
  onSubmit,
  sendingRequest,
}) => {
  return (
    <div>
      <Typography variant="body-1">
        Tell us about an upcoming outdoor experience, and we’ll use our platform
        to show you an example guarantee.
      </Typography>

      <form onSubmit={onSubmit} className={styles.form}>
        <PlacesInput
          placeholder="Location"
          label="Where will you be?"
          value={placesValue}
          handleChange={handlePlacesChange}
          setActivePlace={handleActivePlaceChange}
          apiKey={placesAPIKey}
          errorMsg={placesError}
        />

        <DateRangeInput
          placeholder="Dates"
          label="When is your experience?"
          valueRange={dateRangeValue}
          handleChange={handleDateChange}
          errorMsg={dateError}
          maxDays={14}
        />

        <CurrencyInput
          placeholder="Cost"
          label="How much are you spending?"
          value={currencyValue}
          handleChange={handleCurrencyChange}
          errorMsg={currencyError}
          maxValue={10000}
        />

        <div className={styles.buttonWrapper}>
          <Button
            colorVariant="ocean"
            clickHandling="submit"
            label="Simulate a guarantee"
            loading={sendingRequest}
          />
        </div>
      </form>
    </div>
  );
};

export default Inputs;
