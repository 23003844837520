import React from 'react';
import { Typography, TextButton } from '@sensible/components';
import { ResultsProps } from './Results.types';
import styles from './results.module.scss';

const Results: React.FC<ResultsProps> = ({
  location,
  startDate,
  endDate,
  tripTotalCost,
  tripCostPerDay,
  quotePricePerDay,
  payoutTiers,
  goToFirstStep,
}) => {
  const dateRange = `${startDate} to ${endDate}`;

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeaderSection}>
        <Typography variant="h3">Your Example Guarantee</Typography>
      </div>

      <Typography variant="h5">{location}</Typography>
      <Typography variant="h5">{dateRange}</Typography>
      <Typography variant="h5">{`Total Experience Cost: $ ${tripTotalCost}`}</Typography>
      <Typography variant="h5">{`Experience Cost Per Day: $ ${tripCostPerDay}`}</Typography>

      <div className={styles.costPerDay}>
        <Typography variant="h2">Cost:</Typography>
        <span className={styles.perDayWrapper}>
          <Typography variant="h2">{`$ ${quotePricePerDay}`}</Typography>
          <Typography variant="h6">/day</Typography>
        </span>
      </div>

      <Typography variant="body-2" className={styles.textBlock}>
        Sensible will notify you of the forecast in the morning and
        automatically reimburse you up to 100% of your daily cost if it is
        forecasted to rain. The Rain Guarantee includes coverage from 10am to
        6pm for each day of your reservation and reimburses based on how many
        hours of rain are forecasted:
      </Typography>

      {payoutTiers.map((p, i): JSX.Element => {
        let children = '';
        if (i === 0) {
          children = 'Rain';
        }

        if (p.numberOfHours === 1) {
          children = `${children} for ${p.numberOfHours} hour: $ ${p.payoutAmount}`;
        } else {
          children = `${children} for ${p.numberOfHours} hours: $ ${p.payoutAmount}`;
        }

        return (
          <Typography variant="body-2" key="i">
            {children}
          </Typography>
        );
      })}

      <Typography variant="body-2" className={styles.textBlock}>
        You can duck inside during the rain and enjoy the rest of your day with
        more money in your pocket.
      </Typography>

      <Typography variant="label-3">
        This is an example. Actual guarantee details and cost will vary.
      </Typography>

      {/* TODO: inline default should be false */}
      <div className={styles.startAgain}>
        <TextButton label="start again" clickHandling={goToFirstStep} />
      </div>

      <Typography variant="h3" className={styles.how}>
        How it works
      </Typography>
      <Typography variant="body-2">
        Sensible monitors the weather and automatically notifies you by SMS an
        hour before your experience starts if you&apos;re eligible for
        reimbursement based on the forecast. You&apos;ll be able to see the
        upcoming weather, and easily select a method of reimbursement.
      </Typography>

      <Typography variant="h3" className={styles.how}>
        How it&apos;s built
      </Typography>
      <Typography variant="body-2">
        This simulator is built on Sensible&apos;s public Guarantee API that our
        partners utilize.
        <span className={styles.inlineWrapper}>
          <TextButton
            label="See the Docs"
            clickHandling={{
              url: 'https://sensible.readme.io/docs',
              newTab: true,
            }}
          />
        </span>
      </Typography>
      <Typography variant="body-2">
        Behind the scenes, Sensible&apos;s climate engine manages risk analytics
        and forecast monitoring, and Sensible&apos;s paramatric platform securly
        and reliably manages guarantees, users, and reimbursements.
        <TextButton
          label="Learn more"
          clickHandling={{
            url: 'https://www.sensibleweather.com/our-team',
            newTab: false,
          }}
        />
      </Typography>
    </div>
  );
};

export default Results;
