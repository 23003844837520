import React, { useState, useEffect } from 'react';
import { Typography } from '@sensible/components';
import InputStep from '../../containers/InputStep/InputStep';
import ResultsStep from '../../containers/ResultsStep/ResultsStep';
import ConfigAPI from '../../api/config';
import { SensibleDate } from '../../components/Inputs/Inputs.types';
import styles from './landing.module.scss';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export const Landing: React.FC = () => {
  const [placesToken, setPlacesToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [suggestedPrice, setSuggestedPrice] = useState(0);
  const [payoutTiers, setPayoutTiers] = useState<
    { numberOfHours: number; payoutAmount: number }[]
  >([]);
  const [inputValues, setInputValues] = useState<{
    location: string;
    dates: { startDate: SensibleDate; endDate: SensibleDate };
    coverageAmount: number;
  } | null>(null);

  const goToFirstStep = (): void => {
    setSuggestedPrice(0);
    setPayoutTiers([]);
    setInputValues(null);
    setCurrentStep(1);
  };

  const goToLastStep = (): void => {
    setCurrentStep(2);
  };

  useEffect(() => {
    const getConfig = async (): Promise<void> => {
      /* eslint-disable-next-line */
      try {
        const res = await ConfigAPI.getConfig();

        if (
          !res.REACT_APP_GOOLE_PLACES_TOKEN ||
          typeof res.REACT_APP_GOOLE_PLACES_TOKEN !== 'string'
        ) {
          throw new Error('Error loading application');
        }

        setPlacesToken(res.REACT_APP_GOOLE_PLACES_TOKEN);
      } catch (err) {
        throw err;
      }

      setLoading(false);
    };

    void getConfig();
  }, []);

  if (loading) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.headerSection} ${styles[`step${currentStep}`]}`}
      >
        <Typography variant="display">Simulate a Weather Guarantee</Typography>
      </div>

      {currentStep === 2 && inputValues ? (
        <div className={styles.bodySection}>
          <div className={styles.contentWrapper}>
            <ResultsStep
              location={inputValues.location}
              startDate={inputValues.dates.startDate}
              endDate={inputValues.dates.endDate}
              tripTotalCost={inputValues.coverageAmount}
              quoteTotalPrice={suggestedPrice}
              payoutTiers={payoutTiers}
              goToFirstStep={goToFirstStep}
            />
          </div>
        </div>
      ) : (
        <div className={styles.bodySection}>
          <div className={styles.contentWrapper}>
            <InputStep
              placesToken={placesToken}
              goToLastStep={goToLastStep}
              setSuggestedPrice={setSuggestedPrice}
              setPayoutTiers={setPayoutTiers}
              setInputValues={setInputValues}
            />
          </div>
        </div>
      )}
    </div>
  );
};
